<!--富文本编辑器-->
<template>
  <div>
    <VueTinymce ref="printTemplate" v-model="myValue" v-bind="{ ...$props }" :setup="setups" />
  </div>
</template>

<script>
/**
 * docs:
 * https://panjiachen.github.io/vue-element-admin-site/feature/component/rich-editor.html#tinymce
 */
import tinymce from 'tinymce'
import VueTinymce from './vueTinymce'
export default {
  name: 'Tinymce',
  components: {
    VueTinymce
  },
  props: {
    setting: {
      type: Object,
      default: {
        menubar: true,
        toolbar:
          'code undo redo | formatselect alignleft aligncenter alignright alignjustify | link unlink | numlist bullist | image media table | fontselect fontsizeselect forecolor backcolor | bold italic underline strikethrough | indent outdent | superscript subscript | removeformat |',
        toolbar_drawer: 'sliding',
        quickbars_selection_toolbar:
          'removeformat | bold italic underline strikethrough | fontsizeselect forecolor backcolor',
        plugins: 'code link image media table lists fullscreen',
        language: 'zh_CN', //本地化设置
        height: 350
      }
    },
    // id: {
    //   type: String,
    //   default: function() {
    //     return 'vue-tinymce-' + +new Date() + ((Math.random() * 1000).toFixed(0) + '')
    //   }
    // },
    value: {
      type: String,
      default: ''
    }
    // toolbar: {
    //   type: Array,
    //   required: false,
    //   default() {
    //     return []
    //   }
    // },
    // menubar: {
    //   type: String,
    //   default: 'file edit insert view format table'
    // },
    // height: {
    //   type: [Number, String],
    //   required: false,
    //   default: 360
    // },
    // width: {
    //   type: [Number, String],
    //   required: false,
    //   default: 'auto'
    // }
  },
  created () {
    // this.__proto__.constructor.use(VueTinymce)
    this.__proto__.constructor.prototype.$tinymce = tinymce;
  },
  data() {
    return {
      myValue: this.value
    }
  },
  computed: {
    containerWidth() {
      const width = this.width
      if (/^[\d]+(\.[\d]+)?$/.test(width)) {
        // matches `100`, `'100'`
        return `${width}px`
      }
      return width
    }
  },
  watch: {
    value(newValue) {
      this.myValue = newValue;
    },
    myValue(newValue) {
      this.$emit("input", newValue);
    }
  },
  methods: {
    setups(editor) {
      this.$emit('setups', editor)
    },
    destroyTinymce() {
      const tinymce = window.tinymce.get(this.tinymceId)
      if (this.fullscreen) {
        tinymce.execCommand('mceInsertContent', false, myHTML)
      }

      if (tinymce) {
        tinymce.destroy()
      }
    },
    setContent(value) {
      window.tinymce.get(this.tinymceId).setContent(value)
    },
    getContent() {
      window.tinymce.get(this.tinymceId).getContent()
    },
    imageSuccessCBK(arr) {
      arr.forEach((v) =>
        window.tinymce.get(this.tinymceId).insertContent(`<img class="wscnph" src="${v.url}" >`)
      )
    },
    getPrintTemplate() {
      return this.$refs.printTemplate
    }
  }
}
</script>

<style lang="scss" scoped>
.tinymce-container {
  position: relative;
  line-height: normal;
}

.tinymce-container {
  ::v-deep {
    .mce-fullscreen {
      z-index: 10000;
    }
  }
}

.tinymce-textarea {
  visibility: hidden;
  z-index: -1;
}

.editor-custom-btn-container {
  position: absolute;
  right: 4px;
  top: 4px;
  /*z-index: 2005;*/
}

.fullscreen .editor-custom-btn-container {
  z-index: 10000;
  position: fixed;
}

.editor-upload-btn {
  display: inline-block;
}
</style>
<style>
.tox-collection__group {
  flex-direction: column !important;
  flex-wrap: nowrap !important;
}
.tox .tox-collection__item-icon {
  height: auto !important;
  width: auto !important;
}
</style>
