var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "VueTinymce",
        _vm._b(
          {
            ref: "printTemplate",
            attrs: { setup: _vm.setups },
            model: {
              value: _vm.myValue,
              callback: function ($$v) {
                _vm.myValue = $$v
              },
              expression: "myValue",
            },
          },
          "VueTinymce",
          Object.assign({}, _vm.$props),
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }